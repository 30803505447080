<template>
     <el-dialog title="操作" :visible.sync="DialogShow" append-to-body custom-class='class-dialog' width="700px" @close="onCancel"  :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="dialog-inside videoDialog">
            <div class="sunbox">
                <div class="sunbox-text">标题名称</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="blogroll_name" placeholder="请输入内容" ></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">链接</div>
                <div class="sunbox-content" style="width:500px">
                    <el-input v-model="blogroll_link" placeholder="请输入内容" ></el-input>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            DialogShow:false,
            blogroll_name:'',//标题
            blogroll_link:'',//链接
            id:'',//主建
        };
    },
    
    mounted() {
    },

    methods: {
        // 打开弹窗 || 数据接收
        onDialogShow(data){
            if(data){
                this.id = data.id
                this.blogroll_name = data.blogroll_name,
                this.blogroll_link = data.blogroll_link
            }
            this.DialogShow = true
        },
        //提交
        onSubmit(){
            if (this.blogroll_name == '') {this.$message({ message: "请填写标题", type: "warning" });return;}
            if (this.blogroll_link == '') {this.$message({ message: "请填写链接", type: "warning" });return;}
            var param = {
                id:this.id,
                blogroll_name:this.blogroll_name,
                blogroll_link:this.blogroll_link,
            }
            
            this.$service.post(this.$api.createBlogroll,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message:'操作成功',type: 'success'})
                    this.$emit('onAllDataList');
                    this.onCancel()
                }
            })
        },
        //取消||关闭触发
        onCancel(){
            this.DialogShow = false
            this.blogroll_name = '',//标题
            this.blogroll_link = ''//链接
        },
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    //  var param = {}
    // 	this.$service.get(this.$api.getWxpublicArt,param, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.classifyList{
    .group-table{
        .TableList{
            .el-tag{
                cursor: pointer;
                margin-bottom: 10px;
                margin-right: 20px;
            }
        }
    }
}
</style>
<style lang="scss">
</style>