<template>
    <div class="directorList">
        <div class="padd20 borbut20 paddboutno0">
            <div class="topSearchSou">
                <div class="searchdan ">
                    <div class="title">标题名称：</div>
                    <el-input v-model="blogroll_name" placeholder="请输入内容"></el-input>
                </div>
                <div class="searchdan ">
                    <div class="title">链接：</div>
                    <el-input v-model="blogroll_link" placeholder="请输入内容"></el-input>
                </div>
                <div class="searchdan time">
                    <div class="title">创建时间：</div>
                    <el-date-picker v-model="pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                
                
                <div class="searchdan">
                    <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                    <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' >重置数据</el-button>
                </div>
            </div>
        </div>
        <div class="TableList padd20">
            <div class="duoshao">
                <div class="title">友情链接列表</div>
                <el-button type="primary" icon="el-icon-plus" @click="onDialog()">新增友情链接</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table :data="TableList" empty-text="暂无数据">
                <el-table-column prop="blogroll_name" label="标题名称"></el-table-column>
                <el-table-column prop="blogroll_link" label="链接">
                    <template slot-scope="scope">
                        {{scope.row.blogroll_link}}
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="添加时间"></el-table-column>
                <el-table-column prop="director_wx_code" label="显示状态">
                    <template slot-scope="scope">
                        <el-switch @change="onSwitch(scope.row)" v-model="scope.row.status"  active-color="#13ce66" active-value="1" inactive-value="2"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="date" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDialog(scope.row)" style="margin-left:0">编辑</el-button>
                        <el-button type="warning" @click="ondellink(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            <!-- <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @size-change="handleSizeChange" :page-sizes="[20, 40, 50, 100]" @current-change="DialogCurrentChange" background layout="sizes,prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination> -->
        </div>
        <LinkDialog ref="LinkDialog" @onAllDataList='onAllDataList'></LinkDialog>
    </div>
</template>

<script>
import LinkDialog from './linkDialog.vue'
export default {
    data() {
        return {
            paramData:{},//表格参数
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页on


            pickerTime:'',//时间
            blogroll_name:'',//标题
            blogroll_link:'',//链接
            TableList:[],//数据列表
        };
    },

    components:{
        LinkDialog
    },
    
    mounted() {
        this.onAllDataList()
    },

    methods: {
        // 删除
        ondellink(item){
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var param = {
                    id:item.id
                }
                this.$service.post(this.$api.delBlogroll,param, (res)=> {
                    if(res.code == '200'){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.onAllDataList()
                    }
                })
            }).catch(() => {
                this.$message({type: 'info',message: '已取消删除'});          
            });
        },
        // 状态修改
        onSwitch(data){
            var param = {
                id:data.id,
                status:data.status,
                deleteJiaZai:true
            }
            this.$service.post(this.$api.setBlogrollStatus,param, (res)=> {
                if(res.code == '200'){

                }
            })
        },
        //操作
        onDialog(data){
            this.$refs.LinkDialog.onDialogShow(data)
        },
        //商品搜索
        onSearch(){
            this.paramData = {
                start_time: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_time: this.pickerTime ? this.pickerTime[1] : "", //结束日期
                blogroll_name:this.blogroll_name,//标题
                blogroll_link:this.blogroll_link,//链接
            }
            this.paramPage = 1
            this.onAllDataList()
        },
         // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.onAllDataList()
        },
        //数据列表
        onAllDataList(){
            this.TableList = []
            var param = this.paramData
            param.per_page = this.per_page
            param.page = this.paramPage
            this.$service.get(this.$api.getBlogrollList,param, (res)=> {
                if(res.code == '200'){
                    this.TableList = res.data.data
                    this.lvtotal = res.data.total
                    this.per_page = res.data.per_page*1
                }
            })
        },
         //每页多少条触发条件
        handleSizeChange(val){
            this.per_page = val
            this.onAllDataList()
        },
    },
    
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>